//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { filter, get, map } from 'lodash'

export default {
    props: {
        section: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            activeIndex: 0
        }
    },
    computed: {
        items () {
            return get(this.section, 'fields.items', [])
        }
    },
    methods: {
        filter, get, map,
        item2card (item) {
            if (get(item, 'sys.contentType.sys.id') === 'page') {
                const page = item

                return {
                    page,
                    image: get(page, 'fields.heroImage'),
                    title: get(page, 'fields.title'),
                    description: get(page, 'fields.description')
                }
            }

            if (get(item, 'sys.contentType.sys.id') === 'item') {
                const page = get(item, 'fields.reference')

                return {
                    page,
                    image: get(item, 'fields.media', get(page, 'fields.heroImage')),
                    title: get(item, 'fields.title', get(page, 'fields.title')),
                    description: get(item, 'fields.description', get(page, 'fields.description'))
                }
            }

            return null
        }
    }
}
