import { render, staticRenderFns } from "./Trends.vue?vue&type=template&id=0bd06714&"
import script from "./Trends.vue?vue&type=script&lang=js&"
export * from "./Trends.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSectionHeader: require('/var/www/html/spa-website/components/home/SectionHeader.vue').default,ContentfulImage: require('/var/www/html/spa-website/components/contentful/Image.vue').default,Markdown: require('/var/www/html/spa-website/components/Markdown.vue').default,DynamicLink: require('/var/www/html/spa-website/components/DynamicLink.vue').default,SlopedCarousel: require('/var/www/html/spa-website/components/SlopedCarousel.vue').default})
